$(document).ready(function(){

	Expander.init();
});

window.onload = function(){
    var parts = location.pathname.split('/');
    if(parts[2] !== undefined) {
        $('html,body').animate({scrollTop: $('#' + location.pathname.split('/')[2]).offset().top}, 'slow');
    }
}
var Expander = {
	itemsPerRow: 3,//$('.wall').css('z-index')

	init: function() {
		if( $(window).width() > 700 ){
			this.countItems();
			this.setExpanderColor();
			this.setSeperators(Expander.itemsPerRow);
			this.handleClick();
			this.setExpanderWidth();
			this.setExpanderOffset(Expander.itemsPerRow);
			this.closeButton();
		}
		else{
			this.countItems();
			this.setExpanderColor();
			this.setSeperators(Expander.itemsPerRow);
			this.handleClick();
			this.closeButton();
		}
		

		$(window).resize(function(){

			if( $(window).width() > 700 ){
				Expander.scrollToItemTop(50);
				Expander.setExpanderWidth();
				Expander.setExpanderOffset(Expander.itemsPerRow);
			}
			else{
				Expander.scrollToItemTop(50);
			}
		});
	},

	countItems: function() {
		$(".wall--item").each(function(index){
			$(this).attr('data-id', index + 1);
		});
	},

	handleClick: function() {
	    $('.wall--item--inner').on('click', function(e) {
            var id = [];
			id.push($(e.currentTarget).parent().attr('id'));

			var thisExpander = $(this).siblings('.wall--item--inner--expander');

            if(thisExpander.hasClass('wall--item--inner--expander--active')){
                window.history.replaceState({}, '', '/' + location.pathname.split('/')[1]);
                $('.wall--item--inner--expander').removeClass('wall--item--inner--expander--active');
				$('.wall--item--inner').removeClass('wall--item--inner--active');
            }
			else{

                window.history.replaceState({}, '', '/' + location.pathname.split('/')[1] + '/' + id);
				$('.wall--item--inner').removeClass('wall--item--inner--active');
				$('.wall--item--inner--expander').removeClass('wall--item--inner--expander--active');
				setTimeout(
			        function() {
			            thisExpander.addClass('wall--item--inner--expander--active');
						Expander.scrollToItemTop(500);
			        },
		    	500);
		    	$(this).addClass('wall--item--inner--active');
                //try {
                //    var slug_name = $(this).closest('article').attr('id');
                //    history.pushState('person', '', slug_name);
                //} catch (e) {}
			}
		});
	},

	setExpanderWidth: function() {
		$('.wall--item--inner--expander').width( $(window).width());
	},

	setExpanderOffset: function(offsetNum) {
		$(".wall--item").each(function(index){
			var offsetOfItem = parseInt($(this).offset().left) + parseInt($('.wall--item').css('padding-left') );
			$(this).find('.wall--item--inner--expander').css('margin-left', parseInt('-' + offsetOfItem));			
		});
	},

	setSeperators: function(sepNum) {
		$(".wall--item").each(function(index){
			index = index + 1;
			modulusCounter = index % sepNum;

			if( modulusCounter == 0){
				$('.wall--item[data-id="' + index + '"]').after(function() {
					return '<div class="sep"></div>';
				});
			}
			else{
				//do nothing...
			}
		});
	},

	scrollToItemTop: function(time) {
		if( $('.wall--item--inner--expander').hasClass('wall--item--inner--expander--active') ){
		  var expanderPosition = $(".wall--item--inner--expander--active").offset().top;
			setTimeout(
		        function() {
		            $("html, body").animate({ scrollTop: (expanderPosition - 150) }, time);
		        },
	        100);
		}	
	},

	setExpanderColor: function() {

		for(i=1; i<5; i++){
			$('.wall--item:nth-of-type(4n' + i + ')').each(function(){
				$(this).addClass('ro-' + i);
			});
		}
	},

	closeButton: function() {
        $('.wall--item--inner--expander--close').on('click', function(){
            window.history.replaceState({}, '', '/' + location.pathname.split('/')[1]);
			$('.wall--item--inner--expander').removeClass('wall--item--inner--expander--active');
			$('.wall--item--inner').removeClass('wall--item--inner--active');
		});
	}

}
