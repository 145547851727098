$('.biggie').each(function() {
	var src = $(this).find('img').attr('src');
	$(this).find('img').addClass("killpic");
	$(this).find('.pic').css('backgroundImage','url('+src+')');
});

$('#og-grid').find('> li').each(function() {
	var src = $(this).find('img').attr('src');
	$(this).find('img').addClass("killpic");
	$(this).find('.ogbg').css('backgroundImage','url('+src+')');
});

$('.rw-inner-visible').each(function() {
	var src = $(this).find('img').attr('src');
	$(this).find('img').addClass("killpic");
	$(this).css('backgroundImage','url('+src+')');
});